/* .uiux__grid {
  max-width: 1200px;
  display: inline-block;
  gap: 8em;
} */

.uiux__grid {
  max-width: 1200px;
    /*margin: 0 auto; mikeold*/
  display: grid;
  
  /* grid-template-columns: auto auto auto auto; mikeold*/
  /*grid-template-columns: repeat(auto-fit, minmax(8em, 1fr));*/

  /*grid-template-columns: minmax(20px, auto) 1fr 1fr; /*okay1*/
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr)); /*okay2*/
  

  
  
  /* grid-template-columns: 400px 60px; mikeold*/
  grid-gap: 1.6em;
  /* grid-gap: 10px; mikeold*/
}
